import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/elements/SEO"
import UniversalLink from "./../components/elements/UniversalLink"
import { CreateLocalLinkMenu } from "../utils"
import "./about.scss"
import Image from "../components/elements/image/Image"
import FadeIn from "../components/elements/fadeIn/FadeIn"
import Arrow from "../images/arrow.svg"

export const query = graphql`
    query {
        file(relativePath: { eq: "arrow.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wpPage(databaseId: {eq: 8}) {
            id
            title
            uri
            about {
                tekst
                contact
                afbeelding {
                    sourceUrl
                    srcSet
                }
            }
        }
        wp {
            seo {
                openGraph {
                    defaultImage {
                      sourceUrl
                    }
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const About = ({ data }) => {
    const page = data.wpPage
    const wordPressUrl = data.wp.generalSettings.url.replace(process.env.WORDPRESS_URL, process.env.FRONTEND_URL)

    return (
        <main className="container is-fluid">
            <div className="page-about">
                <div className="element-animation slide-in delay-1">
                    <SEO title={page.title} canonical={wordPressUrl} image={data.wp.seo.openGraph.defaultImage.sourceUrl} />

                    <FadeIn>
                        <div className="section">
                            <h3>{page.title}</h3>
                        </div>
                    </FadeIn>

                    {/* Floating image */}
                    <div className="floating-image delay-1">
                        <Image
                            src={page.about.afbeelding.sourceUrl}
                            srcSet={page.about.afbeelding.srcSet}
                        />
                    </div>

                    {/* Text */}
                    <FadeIn>
                        <div
                            className="section content text-col-two"
                            dangerouslySetInnerHTML={{ __html: page.about.tekst }}
                        />
                    </FadeIn>
                    <FadeIn>
                        {/* Clients + Contact */}
                        <div className="section">
                            <div className="columns">
                                <div className="column is-half">
                                    <h3>Contact</h3>
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                            __html: page.about.contact,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                    <FadeIn>
                        {/* Explore work */}
                        <div className="section next-prev">
                            <UniversalLink className="work-link" to="/">
                                <h3>Explore my work</h3>
                                <div className="arrow">
                                    <Arrow className="arrow" />
                                </div>
                            </UniversalLink>
                        </div>
                    </FadeIn>
                </div>
            </div>
        </main>
    )
}

export default About
